import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { colours, fontSizes, fontFamilies } from '../../src/styling/index';
import { useLocation } from 'react-router-dom'
import { Link, useNavigate } from "react-router-dom";


function Header() {
    const location = useLocation()
    const navigate=useNavigate();
    function logOut(){
        localStorage.clear();
        navigate('/Login')
    }

    return (<Col xs>
        <Row start="xs" className='align-items-center'>


            <img className="intouch-logo" src={require('../img/logo/intouch-mark.png')} alt="Logo" />

            <div style={{
                justifyContent: 'center',
                alignSelf: 'center'
            }}>
                <h1 id="intouch-title" >intouch</h1>
            </div>
            {location.pathname === '/' && (
                <Link to='/Login' className='login-link'>
                    <div className='login-btn'>Log In</div>
                </Link>
            )}

             {location.pathname === '/Admin' &&(
                 <div style={{float:'right'}}>
                <div className='logout-btn' onClick={logOut}>Log Out</div>
                </div>
            )}

        </Row>
    </Col>)
}

export default Header;
