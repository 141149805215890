import React, {useState, useEffect} from "react";
import { Grid, Row, Col } from 'react-flexbox-grid';
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import axios from 'axios';
import CustomPopup from "../components/CustomPopup";


const Support = () => {
  const[FQA, setFQA] = useState(null);
  const[FQATab, setFQATab] =  useState(false);
  const[support, setsupport] =  useState(null);
  const[supportTab, setsupportTab] =  useState(false);
  const [displays, setdisplays] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const[Id, setId] =  useState('');

  function FQAClicked (){
    setFQA(true);
    setFQATab(true);
    setsupport(false);
    setsupportTab(false);
  }

  function supportClicked (){
    setsupport(true);
    setsupportTab(true);
    setFQA(false);
    setFQATab(false);
  }

  const fetchData = () => {
    axios.get('https://intouch.global/scripts/features/Support/support.json',{params: {
     t: new Date().getTime()}
   })
         .then((response) => {console.log(response.data); setdisplays(response.data);})
       };


 useEffect(() => {
   fetchData();
 }, []);

 
 const popupCloseHandler = (e) => {
  setVisibility(!visibility);
};


  return(
    <Grid fluid>
        <Row>
          <Col xs={6} md={3}> 
            <Row>
              <button className={FQA ?'adminButton' : 'deselectedAdminButton'} onClick={() => FQAClicked()} >Help Cards</button>
            </Row>
            <Row>
              <button className={ support?'adminButton' : 'deselectedAdminButton'} onClick={() => supportClicked()}>Support Tickets</button>
            </Row>
          </Col>  

          { FQATab
            ? 
            <div className='FQAContainer'>

              <p  className="adminText" >Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lore</p>

              <div className="FQADiv">
                <p  className="adminText">Social</p>
                <hr className="hline"/>
                <div className="SupportScroll">
                { displays.map((display) => (
                <p key={display.id} className="FQAQuestion" onClick={(e) => setVisibility(!visibility)} onMouseEnter={() => setId(display.id)}>{display.title}</p>
                ))}
                </div>
                <CustomPopup
              onClose={popupCloseHandler}
              show={visibility}
              >
                { displays.map((display) => (
                  Id === display.id ?
                  <div>
                <h1 key={display.id}> {display.title}</h1>
                 <div key={display.id} dangerouslySetInnerHTML={{__html: display.answer}}/>
                 </div>
                :<p></p>
                ))}
                </CustomPopup>
              </div>

              <div className="FQADiv">
                <p  className="adminText">Profile</p>
                <hr className="hline"/>
                <p className="FQAQuestion">FAQ Question</p>
                <p className="FQAQuestion">FAQ Question</p>

              </div>

              <div className="FQADiv">
                <p  className="adminText">Settings</p>
                <hr className="hline"/>
                <p className="FQAQuestion">FAQ Question</p>
              </div>

            </div>
            

          : supportTab
            ? <p>Support Ticket view goes here.</p>
            :<Row className='blankDiv' key="b" data-grid={{ x: 1, y: 0, w: 3, h: 2, static: true  }}>
            
            </Row>}
                      
          </Row>
      </Grid>

  );

}
export default Support;