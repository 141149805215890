import Header from '../components/Header';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useNavigate, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { sessionCheck } from '../reducers/authSlice';
import { Link } from "react-router-dom";

function Deleted() {

  const authSelector = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const sessionOk = authSelector.session;
  const expiredText = authSelector.expiredText;

  const location = useLocation()
  const[dogs, setDogs] =  useState('');
  const[cats, setCats] =  useState('');
  const[userId, setUserId] =  useState('');
  const[username, setUsername] =  useState('');
  const navigate = useNavigate();
  const[disableAcknowledge, setDisableAcknowledge] =  useState(false);
  const[deleteAcknowledge, setDeleteAcknowledge] =  useState(false);
  const[dataCollected, setDataCollected] =  useState(false);

  const getData = async () => {
    try {
      const data = {
        dogs: localStorage.getItem("dogs"),
        cats: localStorage.getItem("cats"),
        userId: localStorage.getItem("userid"),
        username: localStorage.getItem("username"),
      };
      
      setDogs(data.dogs);
      setCats(data.cats);
      setUserId(data.userId);
      setUsername(data.username);
      return 'Complete';
    } catch (e) {
      // error reading value
      return '';
    }
  }

  useEffect(() => {
   
    getData().then(() => {
        setDataCollected(true)
    });
  }, []);

  useEffect(() => {
    console.log(userId);
    submitRequest();
    dispatch(sessionCheck({ dogs: localStorage.getItem("dogs"), cats: localStorage.getItem("cats") }));
    if(location.state.type == "delete"){
        setDeleteAcknowledge(true);
    }
    else if(location.state.type == "disable"){
        setDisableAcknowledge(true);
    }
  }, [dataCollected]);

      

function submitRequest() {
    console.log(userId);
    axios.post('https://intouch.global/scripts/features/settings/deleteAccount.php', {
      "userid": userId,
      "username": username,
      "cats": cats,
      "dogs": dogs,
      "type": location.state.type,
      "reason": location.state.reason,
    }).then((response) => {
      const data = (response.data.Message);
      console.log(response.data.Message);
      if (data == 'No Reason') {
        alert(
          'Please Select a reason and try again'
        );
      }
      else if (data == 'deleted') {
        localStorage.setItem('dogs', '');
        localStorage.setItem('cats', '');
      }
      else if (data == 'disabled') {
        localStorage.setItem('dogs', '');
        localStorage.setItem('cats', ''); 
      }
    }).catch((err) => {
      alert(
        'We are not able to submit your request at this time. Please try again later'
      );
      
    });
  }

  function returnHome() {
    navigate("/Login");
  }
  if(sessionOk){
    return (
      <Grid fluid>
          <Row className='deletedPage'>
          {deleteAcknowledge
              ? <Col xs={6} md={9} className='adminText'>
                  <Row className="adminText">
                    Your account has now been Marked for deletion, you have been logged out of the system
                  </Row>
                  <br />
                  <Row className="adminText">
                    You will be able to apply to restore this account for the next 30 days<br /> after which time it will be fully deleted and not be recoverable.
                  </Row>
                  <br /> 
                  <Row className="adminText">
                    Thank you for using Intouch.
                  </Row>
                  <br /> 
                  
                  
                  <Row>
                    <button className='submitButton' onClick={() => returnHome()}>Complete</button>
                  </Row>
                </Col>
              :disableAcknowledge
              ? <Col xs={6} md={9} className='adminText'>
                  <Row className="adminText">
                    Your account has now been disabled, you have been logged out of the system
                  </Row>
                  <br />
                  <Row className="adminText">
                    Thank you for using Intouch.
                  </Row>
                  <br /> 
                  
                  
                  <Row>
                    <button className='submitButton' onClick={() => returnHome()}>Complete</button>
                  </Row>
                </Col>
                :<Col></Col>}
          </Row>
      </Grid>
      
    );
  }
  else {
    return (
      <div className="profileDiv">
        <div>{expiredText}</div>
        {expiredText == ''
          ? <div></div>
          : <Link to='/Login'>
            <button className='button'>Log In</button>
          </Link>
        }
      </div>
    );
  }
}


export default Deleted;