import Header from '../components/Header';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash, FaMehBlank } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { Grid, Row, Col } from 'react-flexbox-grid';

function Login() {

  const navigate = useNavigate();
  const [dataCollected, setDataCollected] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
 
  const [domain, setDomain] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [username, setUsername] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState('password');
  const [showAccessState, setShowAccessState] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [changed, setChanged] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [exists, setExists] = useState(true);
  const [confirm, setConfirm] = useState(false);


  function showPass() {
    setShowPassword(!showPassword);
    if (showPassword) {
      setInputType('input');
    }
    else {
      setInputType('password');
    }
  }

  const getData = async () => {
    try {
      const data = {
        username: await localStorage.getItem("username"),
        domain: await localStorage.getItem("domain"),
        password: await localStorage.getItem("password"),
        accesscode: await localStorage.getItem("accesscode")
      };

      setUsername(data.username);
      setDomain(data.domain.replace('.rotaflow.online', ''));
      setPassword(data.password);
      setAccessCode(data.accesscode);
      //const value = await AsyncStorage.getItem(key);
      //setData(value); 
      //if(value !== null) {
      // value previously stored
      //}
      return 'Complete';
    } catch (e) {
      // error reading value
      return '';
    }
  }

  useEffect(() => {
    getData().then(() => {
      setDataCollected(true)
   });
    
  }, []);

  useEffect(() => {
    if(queryParams.get('restore') == "delete"){
      setDeleted(true);
      setUsername('');
    }
    else if(queryParams.get('restore') == "disable"){
      setDisabled(true);
      setUsername('');
    }
    else{
      setDeleted(false);
      setDisabled(false);
    }

    if(queryParams.get('changed')){
      setChanged(true);
    }
    else{
      setChanged(false);
    }
  }, [dataCollected]);

  function submitLogin() {
    axios.post('https://intouch.global/scripts/features/authentication/login.php', {
      "username": username,
      "password": password,
      "userType": "fam",
      "from": "web",
      "react": "true",
    }).then((response) => {
      const data = (response.data);
      if (data == 'Incorrect username and/or password') {
        setWrong(true);
        setChanged(false);
      }
      else if ((data == 'This account has been marked for deletion.')) {
        setDeleted(true);
        setUsername('');
      }
      else if ((data == 'This account has been disabled.')) {
        setDisabled(true);
        setUsername('');
      }
      else {
        localStorage.setItem('myValueInLocalStorage', "");
        localStorage.setItem('userid', data.id);
        localStorage.setItem('dogs', data.dogs);
        localStorage.setItem('cats', data.cats);
        localStorage.setItem('pinlength', data.userType);
        localStorage.setItem('username', username);
        localStorage.setItem('firstname', firstname);
        localStorage.setItem('lastname', lastname);
        localStorage.setItem('password', data.hash);
        navigate("/Admin");
      }
    }).catch((err) => {
      alert(
        'Login Failed, Please check credentials and try again'
      );
      console.log(err);
    });
  }

  function sendVerify() {
    axios.post('https://intouch.global/scripts/features/authentication/confirmEmail.php', {
      "username": username,
      "type": deleted ? "deleted" : disabled ? "disabled" : "",

    }).then((response) => {
      console.log(response.data);
      const data = (response.data);
      if (data == 'Email Sent') {
        setConfirm(true);
      }
      else if ((data == 'Please enter a valid email address.')) {
        setExists(false);
      }
      else if ((data ==  'this username does not exist')) {
        setExists(false);
      }
     
    }).catch((err) => {
      alert(
        'Failed to send, please try again later'
      );
      console.log(err);
    });
  }

  function returnHome() {
    setDeleted(false);
    setDisabled(false);
    setConfirm(false);
  }

  return (
    confirm
    ? <Grid fluid>
        <Row className='deletedPage'>
        
          <Col xs={6} md={9} className='adminText'>
            <Row className="adminText">
              An email has been sent, 
            </Row>
            <br />
            <Row className="adminText">
              Please click on the link to complete the account restore process.
            </Row>
            <br /> 
            <Row className="adminText">
              Thank you for using Intouch.
            </Row>
            <br /> 
            <Row>
              <button className='submitButton' onClick={() => returnHome()}>Complete</button>
            </Row>
          </Col> 
        </Row>
    </Grid>
    : deleted
      ? <div className="disabledDiv">
        <div className='adminText'>
          This account has been marked for deletion.<br /><br />
          If you wish to reactivate your account,<br /><br />
          please enter your email address and click the "Restore Account" button. <br /><br />
          You will be sent an Email with a link to restore your account.<br /><br />
        </div>
        {console.log(exists)}
        {exists
        ? <div></div>
        :<div className='redText'>The email address you have entered is incorrect or does not exist on our system.<br /><br /></div>}
        <div className='inputCont'>
          <input
            className='input'
            placeholder='Enter Email'
            onChange={e => setUsername(e.target.value)}
          />

        </div>
        <br />
        <button type='button' className='button' onClick={() => sendVerify()}>Restore Account</button>
      </div>
      : disabled
        ? <div className="disabledDiv">
          <div className='adminText'>
            This account has been disabled.<br /><br />
            If you wish to reactivate your account,<br /><br />
            please enter your email address and click the "Restore Account" button. <br /><br />
            You will be sent an Email with a link to restore your account.<br /><br />
          </div>

          {exists
        ? <div></div>
        :<div className='redText'>The email address you have entered is incorrect or does not exist on our system.<br /><br /></div>}
          <div className='inputCont'>
            <input
              className='input'
              placeholder='Enter Email'
              onChange={e => setUsername(e.target.value)}
            />

          </div>
          <br />
          <button type='button' className='button' onClick={() => sendVerify()}>Restore Account</button>
        </div>
        : <div>
          <div className="landingWrapper">
            <Header />
            <div className="alignLeft marginLeft" style={{
              flexBasis: '100%',
            }}>
              <div className="header paddingBottom">Welcome to the InTouch Website</div>
              <div className=" paddingBottom"> Please enter your credentials to log in</div>
              {changed
              ? <div className='redText'><br />Your Password has been changed. Please log in with your new password<br /><br /></div>
              : wrong
              ? <div className='redText'><br />Your Username or Password is incorrect. Please check your details and try again<br /><br /></div>
              : <div><br /><br /></div>}
              <div>Username</div>
              <div className='inputCont'>
                <input
                  className='input'
                  placeholder='Enter Username'
                  onChange={e => setUsername(e.target.value)}
                />
                <button className='eyeButton' disabled><FaMehBlank name="eye-outline" size={25} color="transparent" /></button>

              </div>
              <div>Password</div>
              <div className='inputCont'>
                <input
                  className='input'
                  type={showPassword ? "text" : "password"}
                  placeholder='Enter Password'
                  onChange={e => setPassword(e.target.value)}
                />

                <button onClick={() => showPass()} className='eyeButton'>
                  {showPassword
                    ? <FaEyeSlash name="eye-outline" size={25} color="#A7D3D5" />
                    : <FaEye name="eye-off-outline" size={25} color="#A7D3D5" />
                  }
                </button>
              </div>
              <br />
              <button type='button' className='button' onClick={() => submitLogin()}>Login</button>

            </div>

          </div>
        </div >
  );
}


export default Login;