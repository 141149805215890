import { autocompleteClasses } from '@mui/material';
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { colours, fontSizes, fontFamilies } from '../../src/styling/index';


const Footer = () => (
    <div className="footer">
       <a href='https://testbed.evosoftwaresolutions.com/?p=landing&l=en-gb'> <img className="evologo" src={require('../img/logo/Battery Light.png')} alt="Logo" /></a>
        <div className='footertext'>
        <a className='link'  href='https://testbed.evosoftwaresolutions.com/?p=about-us&l=en-gb'>About</a>
        <div className='spacer'></div>
        <a className='link' href='https://testbed.evosoftwaresolutions.com/?p=contact&l=en-gb'>Contact</a>
        </div>
    </div>
  );

export default Footer;
