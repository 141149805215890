import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sessionCheck = createAsyncThunk(
    'sessionCheck', async ({ dogs, cats }) => {
        const response = await axios.post('https://intouch.global/scripts/features/authentication/session_check.php', {
            "dogs": dogs,
            "cats": cats,
            "react": "true",
        });
        if (response.data === 'Session_confirmed') {
            return {
                session: true ?? '',
                expiredText: '' ?? '',
            }
        } else {
            return {
                session: false ?? '',
                expiredText: 'This session has expired, Please go back and log in again' ?? '',
            }
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        session: false,
        expiredText: '',
    },
    extraReducers: (builder) =>
        builder.addCase(sessionCheck.fulfilled, (state, action) => {
                state.session = action.payload.session;
                state.expiredText = action.payload.expiredText;
            })
            .addCase(sessionCheck.rejected, (state, action) => {
                state.session = action.payload.session;
                state.expiredText = action.payload.expiredText;
            })
});

export default authSlice.reducer;