import { flexbox, margin } from '@mui/system';
import React, { useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { colours, fontSizes, fontFamilies } from '../styling/index';
import '../styling/index.css';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { sessionCheck } from '../reducers/authSlice';



function Body() {
    const authSelector = useAppSelector((state) => state.auth);
    const value = authSelector.value;
    const session = authSelector.session;
    const dispatch = useAppDispatch();
    console.log(authSelector);
    useEffect(() => {
        dispatch(sessionCheck({ dogs: localStorage.getItem("dogs"), cats: localStorage.getItem("cats") }));
    }, []);

    function adminLink() {
        alert('clicked');

    }
    //console.log(value);
    return (
        <Col>

            <div className="landing-body" style={{
                display: 'flex',
            }}>
                <Col style={{
                    justifyContent: 'space-around',
                    alignSelf: 'center',
                }}>
                    <img className="intro-img" src={require('../img/screens/Hello.png')} alt="Logo" />

                </Col>
                <p className='flex1'></p>
                <Col className="divblock1" style={{ flex: 3, flexDirection: 'column', display: 'flex',  }}>
                    <p style={{
                        textAlign: 'left',
                    }}>Intouch is the latest evolution of social media. It is a breath of fresh air within a market that’s whole business model relies on the addictiveness of their platform and profits from selling the users data, we have broken the mould and given power back to the user. We strongly believe that your data belongs in your hands and that is why you don’t have to worry about where your data will end up. Intouch was built with family in mind, from all ages young to old taken into consideration when designing the platform. Its seamless onboarding of those who aren’t tech savvy lets a designated group administrator take control of the whole process creating an account for your loved one and sharing access codes to the rest of the family. Once connected you will be able to start sharing messages, photos and videos with the whole family! 
                    </p>

                    <p className='flex025' />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'center',
                        justifyContent: 'space-around',
                    }}>
                         <a href='https://apps.apple.com/us/app/intouch-app/id1589270809'><div id="download-ios" className='landing-btn'>Download for iOS</div></a>
                        <div className='or-flex'>
                            <div className='or-line' />
                            <p className='or-text'>or</p>
                            <div className='or-line' />
                        </div>
                        <a href='https://play.google.com/store/apps/details?id=online.evolving.intouch'><div id="download-android" className='landing-btn' >Download for android</div></a>
                    </div>
                </Col>
                <p className='flex1'></p>
            </div >
        </Col >
    )
}

export default Body;
