
import axios from 'axios';
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Header from '../components/Header';

const Profile = () => {

    const [dataCollected, setDataCollected] = useState(false);
    const [sessionOk, setSessionOk] = useState(false);
    const [expiredText, setExpiredText] = useState('');
    const [dogs, setDogs] = useState('');
    const [cats, setCats] = useState('');

    const getData = async () => {
        try {
            const data = {dogs:await localStorage.getItem("dogs"),
            cats:await localStorage.getItem("cats")};
            setDogs(data.dogs);
            setCats(data.cats); 
          return 'Complete';
        } catch(e) {
          // error reading value
          return '';
        }
    }
    
    useEffect(() => {
        getData().then (() => {
          setDataCollected(true);
         
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    function sessionCheck() {
        console.log("dogs = " + dogs);
        console.log("cats = " + cats);
        axios.post('https://intouch.global/scripts/features/authentication/session_check.php', {
            "dogs": dogs,
            "cats": cats,
            "react": "true",
        }).then((response) => {   
            if(response.data === "Session_confirmed"){
                setSessionOk(true);

            }   
            else{
                setExpiredText(' Session has expired or has an error, Please click here to return to the login page.');
            }  
        }).catch((err) => {
            console.log(err);
        });
    }
 
    useEffect(() => {
        if(dataCollected){
            sessionCheck();
        }
    },[dataCollected]);

    if(sessionOk){
        return (
            <div className="profileDiv">
                <Header />
                <h1 className="title is-1">Restore User</h1>
                <article className="message is-dark" style={{ marginTop: 40 }}>
                    <div className="message-header">
                    
                    </div>
                    <Link to="/">
                        <div className="message-body">Home</div>
                    </Link>
                </article>
            </div>
        );

    }
    else{
        return (
            <div className="profileDiv">
               <div>{expiredText}</div>
               {expiredText == ''
                ?  <div></div>
                :  <Link to='/Login'>
                <button className='button'>Log In</button>
            </Link>
        }
            </div>
        );
    }
};
export default Profile;