import React from 'react';
import logo from './logo.svg';
import Header from '../src/components/Header';
import Body from '../src/components/Body';
import Footer from '../src/components/Footer';
import { colours, fontSizes, fontFamilies } from '../src/styling/index';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Grid, Row, Col } from 'react-flexbox-grid';
import './styling/index.css'

import LoginPage from './pages/Login';
import AdminPage from './pages/Admin';
import LandingPage from './pages/Landing';
import EcPage from './pages/support';
import AdminTab from './pages/AdminTab';
import RestoreUser from './pages/RestoreUser';
import Deleted from './pages/Deleted';

function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/support" element={<EcPage />} />
        <Route path="/profileTab" element={<AdminTab />} />
        <Route path="/restoreUser" element={<RestoreUser />} />
        <Route path="/deleted" element={<Deleted />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;



