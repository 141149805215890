import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Tabs from "../components/Tabs";
import upport from './support';
import AdminTab from './AdminTab';
import Header from '../components/Header';
import Support from "./support";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { sessionCheck } from '../reducers/authSlice';
import Footer from "../components/Footer";
import Connections from "./Connections";
import axios from 'axios';

const Admin = () => {
  const authSelector = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const sessionOk = authSelector.session;
  const expiredText = authSelector.expiredText;
  const[userId, setUserId] =  useState('');

  const getData = async () => {
      const data = {
        userId: localStorage.getItem("userid"),
      };
      setUserId(data.userId);
    }
    

  useEffect(() => {
    getData();
  }, []);

  axios.post('https://intouch.global/scripts/features/Connections/Connections.php', {
    "userid": userId, 
  },{params: {
    t: new Date().getTime()}
  });

  axios.post('https://intouch.global/scripts/features/Support/Support.php', {
  },{params: {
    t: new Date().getTime()}
  });

  useEffect(() => {
    dispatch(sessionCheck({ dogs: localStorage.getItem("dogs"), cats: localStorage.getItem("cats") }));
  }, []);

  

  if(sessionOk){
    return(
      <div className="adminWrapper">
      <div className="alignLeft">
        <div className="adminHeader">
          <Header />
        </div>
        
       
        <Tabs>
          <div label="Admin" position="0">
            <AdminTab />
          </div>
          <div label="Support" position="1">
          <Support/>
          </div>
          <div label="Connections" position="2">
          <Connections/>
          </div>
        </Tabs>
          <div ></div>
        
        </div>
        <Footer/>
      </div>
    )
  }
  else {
    return (
      <div className="profileDiv">
        <div>{expiredText}</div>
        {expiredText == ''
          ? <div></div>
          : <Link to='/Login'>
            <button className='button'>Log In</button>
          </Link>
        }
      </div>
    );
  }
}

export default Admin;