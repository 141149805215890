import Header from '../components/Header';
import Body from '../components/Body';
import Footer from '../components/Footer';

function Landing() {
  return (
    <div className="landingWrapper">
      <Header />
      <p className='flex025'></p>
      <Body />
      <p className='flex1'></p>
      <Footer />
    </div>
  );
}

export default Landing;