import React, {useState, useEffect} from "react";
import { Grid, Row, Col } from 'react-flexbox-grid';
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import axios from 'axios'
import { useNavigate, Link } from "react-router-dom";
import { FaEye, FaEyeSlash, FaMehBlank } from 'react-icons/fa';

const Profile = () => {
  const navigate = useNavigate();
  const[disable, setDisable] = useState(null);
  const[reset, setReset] =  useState(null);
  const[deleteAcc, setDeleteAcc] =  useState(null);
  const[disableReason, setDisableReason] =  useState('nothing');
  const[deleteReason, setDeleteReason] =  useState('nothing');
  const[dogs, setDogs] =  useState('');
  const[cats, setCats] =  useState('');
  const[userId, setUserId] =  useState('');
  const[username, setUsername] =  useState('');
  const[type, setType] =  useState('');
  const[disablConf, setDisablConf] =  useState(false);
  const[deleteConf, setDeleteConf] =  useState(false);
  const[noReason, setNoReason] =  useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [currentInputType, setCurrentInputType] = useState('password');
  const [newInputType, setNewInputType] = useState('password');
  const [confInputType, setConfInputType] = useState('password');
  const [match, setMatch] = useState(true);
  const [short, setShort] = useState(false);
  const [weak, setWeak] = useState(false);
  const [check, setCheck] = useState(true);

  function showCurrentPass() {
    setShowCurrentPassword(!showCurrentPassword);
    if (showCurrentPassword) {
      setCurrentInputType('input');
    }
    else {
      setCurrentInputType('password');
    }
  }

  function showNewPass() {
    setShowNewPassword(!showNewPassword);
    if (showNewPassword) {
      setNewInputType('input');
    }
    else {
      setNewInputType('password');
    }
  }

  function showConfPass() {
    setShowConfPassword(!showConfPassword);
    if (showConfPassword) {
      setConfInputType('input');
    }
    else {
      setConfInputType('password');
    }
  }
  
  const getData = async () => {
    try {
      const data = {
        dogs: localStorage.getItem("dogs"),
        cats: localStorage.getItem("cats"),
        userId: localStorage.getItem("userid"),
        username: localStorage.getItem("username"),
      };
      setDogs(data.dogs);
      setCats(data.cats);
      setUserId(data.userId);
      setUsername(data.username);
      return 'Complete';
    } catch (e) {
      // error reading value
      return '';
    }
  }
  useEffect(() => {
    getData();
  }, [])

  function  disableClicked() {
    setDisableReason('nothing');
    setDeleteReason('nothing');
    setNoReason(false);
    setDisablConf(false);
    setDeleteConf(false);
    setDisable(true);
    setReset(false);
    setDeleteAcc(false);
    setType('disable');
  }

  function resetClicked() {
    setDisableReason('nothing');
    setDeleteReason('nothing');
    setNoReason(false);
    setDisablConf(false);
    setDeleteConf(false);
    setDisable(false);
    setReset(true);
    setDeleteAcc(false);
    setType('reset');
  }

  function deleteClicked() {
    setDisableReason('nothing');
    setDeleteReason('nothing');
    setNoReason(false);
    setDisablConf(false);
    setDeleteConf(false);
    setDisable(false);
    setReset(false);
    setDeleteAcc(true);
    setType('delete');
  }

  function disableButton() {
    if(disableReason == "nothing"){
      setNoReason(true);
    }
    else{
      setDisablConf(true);
      setDisable(false);
      setReset(false);
      setDeleteAcc(false);
      setType('disable');
    }
  }

  function deleteButton() {
    if(deleteReason == "nothing"){
      setNoReason(true);
    }
    else{
      setDeleteConf(true);
      setDisable(false);
      setReset(false);
      setDeleteAcc(false);
      setType('delete');
    }
  }
  function returnHome() {
    navigate("/Login");
  }


  function changePassword() {
    if(newPassword == confPassword){
      setMatch(true);
      setCheck(true);
      setShort(false);
      setWeak(false);
      axios.post('https://intouch.global/scripts/features/settings/updatePassword.php', {
        "userid": userId,
        "username": username,
        "current": currentPassword,
        "new": newPassword,
        "cats": cats,
        "dogs": dogs,
        "type": type,
      }).then((response) => {
        
        const data = (response.data.Message);
        console.log(response.data.Message);
        if (data == 'Short') {
          setShort(true);
        }
        else if (data == 'Not strong enough') {
          setShort(false);
          setWeak(true);
        }
        else if (data == 'Current password not verified') {
          setShort(false);
          setWeak(false);
          setCheck(false);
        }
        else if (data == 'Password updated successfully!') {
          navigate("/Login?changed=true");
        }
        
      }).catch((err) => {
        alert(
          'We are not able to submit your request at this time. Please try again later'
        );
      
      });
    }
    else{
     setMatch(false);
     setCheck(true);
     setShort(false);
     setWeak(false);
    }
  }
  return (
    <Grid fluid>
        <Row>
          <Col xs={6} md={3}> 
            <Row>
              <button className= {disable ?'adminButton' : 'deselectedAdminButton'} onClick={() => disableClicked()}>Disable your Account</button>
            </Row>
            <Row>
              <button className= {reset ?'adminButton' : 'deselectedAdminButton'} onClick={() => resetClicked()}>Reset your Password</button>
            </Row>
            <Row>
              <button className= {deleteAcc ?'adminButton' : 'deselectedAdminButton'} onClick={() => deleteClicked()}>Delete your Account</button>
            </Row> 
          </Col>
        
            {disablConf 
            ? <Col xs={6} md={9} className='adminText'>
                <Row className="adminText">
                  We are sorry that you wish to disable your account,
                </Row>
                <br />
                <Row className="adminText">
                  Please confirm that this account is to be disabled.
                </Row>
                <br /> 
                <Row className="adminText"> Reason : {disableReason == "notUsing"  ? "Not using the service" : "Other"}</Row>
                
                <Row>
                <Link to='/Deleted' state={{ type: type, userid: userId,   reason: type == 'delete' ? deleteReason : disableReason }} className='submitLink'>Disable Account</Link>
                </Row>
              </Col>
          : deleteConf
            ? <Col xs={6} md={9} className='adminText'>
                <Row className="adminText">
                  We are sorry that you wish to delete your account,
                </Row>
                <br />
                <Row className="adminText">
                  Your account will be deleted in 30 days, After this time it will not be recoverable.
                </Row>
                <br />
                <Row className="adminText">
                 Are you sure you wish to delete your account?
                </Row>
                <br />
                <Row className="adminText"> Reason : {deleteReason == "notUsing"  ? "Not using the service" : "Other"}</Row>
               
                <Row>
                <Link to='/Deleted' state={{ type: type, userid: userId,   reason: type == 'delete' ? deleteReason : disableReason }} className='submitLink'>Delete Account</Link>
                </Row>
              </Col>
          :  disable
            ? <Col xs={6} md={9} className='adminText'>
                <Row className="adminText">
                  We are sorry that you wish to disable your account,
                </Row>
                <br />
                <Row className="adminText">
                  Please help us improve by selecting the reason for disabling your account.
                </Row>
                <br /> 
                <Row className="adminText"> Reason : </Row>
                <Row>
                  <select className="select" placeholder="Select"  onChange={(event) => setDisableReason(event.target.value)}>
                    <option value="nothing">Select</option>
                    <option value="notUsing">Not using the service.</option>
                    <option  value="another">Another reason</option>
                    <option value="anotherStill">Another reason Still</option>
                  </select>
                </Row>
                {noReason
                ?<div className="redText">Please select a reason before continuing</div>
                :<div className="redText"></div>}
                <Row>
                  <button className='submitButton' onClick={() => disableButton()}>Submit</button>
                </Row>
              </Col>
            : reset 
            ? <Col xs={6} md={9} className='adminText'>
                <Row className="adminText">
                  Please enter your current password
                </Row>
                <div>Current Password</div>
                <div className='inputCont'>
                  <input
                    className='input'
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder='Enter Password'
                    onChange={e => setCurrentPassword(e.target.value)}
                  />

                  <button onClick={() => showCurrentPass()} className='eyeButton'>
                    {showCurrentPassword
                      ? <FaEyeSlash name="eye-outline" size={25} color="#A7D3D5" />
                      : <FaEye name="eye-off-outline" size={25} color="#A7D3D5" />
                    }
                  </button>
                </div>
                <Row className="adminText">
                  Select a new Password
                </Row>
                <div>New Password</div>
                <div className='inputCont'>
                  <input
                    className='input'
                    type={showNewPassword ? "text" : "password"}
                    placeholder='New Password'
                    onChange={e => setNewPassword(e.target.value)}
                  />

                  <button onClick={() => showNewPass()} className='eyeButton'>
                    {showNewPassword
                      ? <FaEyeSlash name="eye-outline" size={25} color="#A7D3D5" />
                      : <FaEye name="eye-off-outline" size={25} color="#A7D3D5" />
                    }
                  </button>
                </div>
                <div>Confirm New Password</div>
                <div className='inputCont'>
                  <input
                    className='input'
                    type={showConfPassword ? "text" : "password"}
                    placeholder='Confirm New Password'
                    onChange={e => setConfPassword(e.target.value)}
                  />

                  <button onClick={() => showConfPass()} className='eyeButton'>
                    {showConfPassword
                      ? <FaEyeSlash name="eye-outline" size={25} color="#A7D3D5" />
                      : <FaEye name="eye-off-outline" size={25} color="#A7D3D5" />
                    }
                  </button>
                </div>
                {!match 
                ? <div className="redText">The passwords do not Match, please check and try again.<br /><br /><br /></div>
               : short 
                ? <div className="redText">The password is Too short, please check and try again.<br /><br /><br /></div>
               :weak 
                ? <div className="redText">Password is not strong enough,<br /> please ensure it contains a lowercase character, an uppercase character, a number ,<br />and at least one of the following symbols (!, %, &, @, #, $, ^, *, ?, _, ~)</div>
                :check 
               ? <div><br /><br /><br /></div>
               :  <div className="redText">The current password could not be verified, please check and try again.<br /><br /><br /></div>}
                <button type='button' className='button' onClick={() => changePassword()}>Change Password</button>
              </Col>
            :deleteAcc 
            ? <Col xs={6} md={9} className='adminText'>
              
                <Row className="adminText">
                We are sorry that you wish to delete your account,
                </Row>
                <br />
                <Row className="adminText">
                Please help us improve by selecting the reason for deleting your account.
                </Row>
                <br />
                <Row className="adminText">
               <p> Once <span className='redText'>Deleted</span> you will have 30 days to restore your account after which it will no longer be recoverable.</p>
                </Row>
                <br /> 
             
                <Row className="adminText">Reason : </Row>
                <Row>
                <select className="select" placeholder="Select" onChange={(event) => setDeleteReason(event.target.value)}>
                  <option value="nothing">Select</option>
                  <option value="notUsing">Not using the service.</option>
                  <option value="another">Another reason</option>
                  <option value="anotherStill">Another reason Still</option>
                </select>
                </Row>
                {noReason
                ?<div className="redText">Please select a reason before continuing</div>
                :<div className="redText"></div>}
                <Row>
                <button className='submitButton'onClick={() => deleteButton()}>Submit</button>
                </Row>
              
            </Col>
            :<Row className='blankDiv' key="b" data-grid={{ x: 1, y: 0, w: 3, h: 2, static: true  }}>
            
            </Row>}
         
      </Row>
        
      </Grid>
  );
};

export default Profile;