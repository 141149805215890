import React, {useState, useEffect} from "react";
import { Grid, Row, Col } from 'react-flexbox-grid';
import axios from 'axios';
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import CustomPopup from "../components/CustomPopup";
import { FaEye, FaEyeSlash, FaMehBlank } from 'react-icons/fa';
import { ReactComponent as Add } from '../img/add.svg';

//disconnect, connect, admin or user need to be clicked twice

const Connections = () => {
  const[Con, setCon] = useState(null);
  const[ConTab, setConTab] =  useState(false);
  const [displays, setdisplays] = useState([]);
  const [famdisplays, setfamdisplays] = useState([]);
  const [invitedisplays, setinvitedisplays] = useState([]);
  const [Error, setError] = useState([]);
  const [CreateLovedOneError, setCreateLovedOneError] = useState([]);
  const [CreateLovedOneSuccess, setCreateLovedOneSussess] = useState([]);
  const [VerifyError, setVerifyError] = useState([]);
  const [VerifySuccess, setVerifySuccess] = useState([]);
  const [Success, setSuccess] = useState([]);
  const [RemoveConnectionSuccess, setRemoveConnectionSuccess] = useState([]);
  const [RemoveConnectionError, setRemoveConnectionError] = useState([]);
  const [AssginAdminSuccess,  setAssginAdminSuccess] = useState([]);
  const [AssignAdminError,  setAssignAdminError] = useState([]);
  const[userId, setUserId] =  useState('');
  const [visibility, setVisibility] = useState(false);
  const[disconnectAcknowledge, setdisconnectAcknowledge] =  useState(false);
  const[connectAcknowledge, setconnectAcknowledge] =  useState(false);
  const[Admin, setAdmin] =  useState(false);
  const[type, setType] =  useState('');
  const[AccessKey, setAccessKey] =  useState('');
  const[Password, setPassword] =  useState('');
  const[Id, setId] =  useState('');
  const[FamId, setFamId] =  useState('');
  const[Invite, setInvite] =  useState(false);
  const[Reset, setReset] =  useState(false);
  const[disconnect, setdisconnect] =  useState(false);
  const[verify, setverify] =  useState(false);
  const[Edit, setEdit] =  useState(false);

  const [VerifyPassword, setVerifyPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [LovedOnePassword, setLovedOnePassword] = useState('');
  const [noMatch, setNoMatch] = useState(false);
  const [Match, setMatch] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [showLovedOnePassword, setShowLovedOnePassword] = useState(false);
  const [VerifyInputType, setVerifyInputType] = useState('password');
  const [showVerifyPassword, setshowVerifyPassword] = useState(false);
  const [newInputType, setNewInputType] = useState('password');
  const [confInputType, setConfInputType] = useState('password');
  const [LovedOneInputType, setLovedOneInputType] = useState('password');

  const[CreateUsername, setCreateUsername] =  useState('');
  const[CreatePassword, setCreatePassword] =  useState('');
  const[Firstname, setFirstName] =  useState('');
  const[Lastname, setlastname] =  useState('');
  const[username, setUsername] =  useState('');

  function showVerifyPass() {
    setshowVerifyPassword(!showVerifyPassword);
    if (showVerifyPassword) {
      setVerifyInputType('input');
    }
    else {
      setVerifyInputType('password');
    }
  }

  function showNewPass() {
    setShowNewPassword(!showNewPassword);
    if (showNewPassword) {
      setNewInputType('input');
    }
    else {
      setNewInputType('password');
    }
  }

  function showConfPass() {
    setShowConfPassword(!showConfPassword);
    if (showConfPassword) {
      setConfInputType('input');
    }
    else {
     setConfInputType('password');
    }
  }

  function showLovedOnePass() {
    setShowLovedOnePassword(!showLovedOnePassword);
    if (showLovedOnePassword) {
      setLovedOneInputType('input');
    }
    else {
     setLovedOneInputType('password');
    }
  }

  const getData = async () => {
      const data = {
        userId: localStorage.getItem("userid"),
        username: localStorage.getItem("username"),
      };
      setUserId(data.userId);
      setUsername(data.username);
    }
    
  useEffect(() => {
    getData();
  }, []);
  
  const fetchData = () => {
     axios.get('https://intouch.global/scripts/features/Connections/connections.json',{params: {
      t: new Date().getTime()}
    })
          .then((response) => {console.log(response.data); setdisplays(response.data);})
        };


  useEffect(() => {
    fetchData();
  }, []);

  
  const Fam = () => {
    axios.get('https://intouch.global/scripts/features/Connections/famConnections.json',{params: {
     t: new Date().getTime()}
   })
         .then((response) => {console.log(response.data); setfamdisplays(response.data);})
       };


 useEffect(() => {
   Fam();
 }, []);



  function ConClicked (id){
    setId(id);
    axios.post('https://intouch.global/scripts/features/Connections/ConnectionsFam.php', {
    "userid": userId, 
    "id": id, 
  },{params: {
    t: new Date().getTime()}
  }).then((response) => {console.log(response.data); const Admindata = (response.data);

    if (Admindata === 'Admin') {
      setAdmin(true); 
    }else{
      setAdmin(false);
    }    
  });

  setCon(false);
  setConTab(true);

  /*
    if(Con){
      setCon(true);
      setConTab(false);
    }else{
        setCon(false);
        setConTab(true);
    }
*/
    /*
    if(displays.length > 1){
      console.log('more than 1');
         }else{
          console.log('only 1');
         }
        }
      */
     }

     function AddClicked() {
      setCon(true);
      setConTab(false);
    }
  

  function Disconnect() {
    //setType("disconnect");
    axios.post('https://intouch.global/scripts/features/Connections/Disconnect.php', {
      "userid": userId, 
      "id" : Id,
      "type" : "disconnect",
    },{params: {
      t: new Date().getTime()}
    }).then((response) => {console.log(response.data); });

    if(type === "disconnect"){
      setdisconnectAcknowledge(true);
      setTimeout(function(){
        window.location.reload(1);
     }, 2000);
    }

  }

  function Connect()  {
    //setType("Connect");
   axios.post('https://intouch.global/scripts/features/Connections/Disconnect.php', {
      "userid": userId, 
      "type" : 'Connect',
      "code" : AccessKey,
    },{params: {
      t: new Date().getTime()}
    }).then((response) => {console.log(response); const data = (response.data);
      if (data === "Success") {
        setError(true); 
        setSuccess(false);
        setTimeout(function(){
          window.location.reload(1);
       }, 2000);
      }else if(data === 'Error'){
        setSuccess(true);
        setError(false); 
        
      }
    });

    
    if(type === "Connect"){
      setconnectAcknowledge(true);
    }
    
  }
  
  //console.log(displays.length);
  console.log(Id);

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  function invite () {
    setInvite(true);
    setReset(false);
    setdisconnect(false);
    setEdit(false);

    axios.post('https://intouch.global/scripts/features/settings/createConnection.php', {
      "res_id" : Id,
    }).then((response) => {
      console.log(response.data); setinvitedisplays(response.data);
      
    });
  }

  function resetshow () {
    setReset(true);
    setInvite(false);
    setdisconnect(false);
    setEdit(false);
  }

  function disconnectshow (){
    setdisconnect(true);
  }

  function Verify (){
    setverify(true);
    setReset(false);
    setInvite(false);
    setdisconnect(false);
    setEdit(false);

    axios.post('https://intouch.global/scripts/features/settings/verifyPassword.php', {
      "user_id": userId, 
      "password": Password,
    },{params: {
      t: new Date().getTime()}
    }).then((response) => {console.log(response.data); const verifydata = (response.data.Status);
      if (verifydata === "Error") {
         setVerifyError(true);
      }else if (verifydata === "Success"){
        setVerifySuccess(true);
      }
    });

  }

  function Editconnection (){
    setEdit(true);
    setverify(null);
    setReset(false);
    setInvite(false);
    setdisconnect(false);
  }


  function changePassword() {
    if(newPassword == confPassword){
      axios.post('https://intouch.global/scripts/features/settings/updateOtherPassword.php', {
        "user_id": Id,
        "new_password": newPassword,
      }).then((response) => {
        
        const Passworddata = (response.data.Status);
        console.log(response.data.Status);

        if(Passworddata === 'Success'){
          setMatch(true);
          setNoMatch(false);
        }
        
      
      });
    
    }else{
      setNoMatch(true);
    }
  }

  function CreateLovedOne(){
if (!CreateUsername, !CreatePassword, !Firstname, !Lastname ){

  setCreateLovedOneError(true);
}else{
    axios.post('https://intouch.global/scripts/features/authentication/createProfile.php', {
      "username" : CreateUsername,
      "password": CreatePassword,
      "firstname":Firstname,
      "lastname": Lastname,
      "admin": username,
    }).then((response) => {
      console.log(response.data);
      setCreateLovedOneSussess(true);
      setCreateLovedOneError(false);
    });
  }
  }

  function RemoveConnection (){
    axios.post('https://intouch.global/scripts/features/settings/removeConnection.php', {
      "res_id": Id,
      "fam_id": FamId,
    }).then((response) => {
      console.log(response.data); const RemoveConnectiondata = (response.data);
      if (RemoveConnectiondata === 'Connection removed'){
        setRemoveConnectionSuccess(true);
        setRemoveConnectionError(false);
      }else if (RemoveConnectiondata === 'Admin'){
        setRemoveConnectionError(true);
        setRemoveConnectionSuccess(false);
      }
    });
  }

  function AssignAdmin (){
    axios.post('https://intouch.global/scripts/features/settings/assignConnection.php', {
      "res_id": Id,
      "fam_id": FamId,
      "user_id": userId,
    }).then((response) => {
      console.log(response.data); const AssignAdmindata = (response.data.isadmin);

      if(AssignAdmindata === "false"){
        setAssginAdminSuccess(true);
        setAssignAdminError(false);
      }else if(AssignAdmindata === "true"){
        setAssginAdminSuccess(false);
        setAssignAdminError(true);
      }
    });
  }

  return(

    <Grid fluid>
        <Row>
          <Col xs={6} md={3}> 
            <Row>
            <div className="ConDiv">
            { displays.length === 0 ?
            <p className="adminText">No Connections</p> :
            <p className="adminText">Your Connections</p> }
                <hr className="hline"/>
                <Add className="AddImg" onClick={() => AddClicked()}/>
                </div>
                <div className="Scroll">
                 { displays.map((display) => (
                   Id === display.id ?
              <button key={display.id} className={ !Con ?'adminButton' : 'deselectedAdminButton'}  onClick={() => ConClicked(display.id)}  >{display.displayname}</button>
              :<button key={display.id} className={'deselectedAdminButton' }  onClick={() => ConClicked(display.id)}  >{display.displayname}</button>
                ))}
                </div>
            </Row>

            
            {Con
            ? 
            <div>
            <img className="ConImg" src={require('../img/screens/notifications.png')} alt="Con_img"  />
            <div className="ConNameDiv2">
            <p className="adminText">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lore</p>
            <button className='submitButton' onClick={() => {setVisibility(!visibility); setconnectAcknowledge(true);}} >Connect Loved one</button>
            <br/>
            <button className='submitButton' onClick={() => {setVisibility(!visibility); setconnectAcknowledge(false);}}>Create Loved one</button>
            <CustomPopup
              onClose={popupCloseHandler}
              show={visibility}
              >
            { connectAcknowledge === true
            ?
            <div>
              <h1>Please enter an access code to connect.</h1>
              {Success ? <div></div> 
              : <div className='greenText'>You have successfully Connected to Loved-one!<br /><br /></div>}
              {Error ? <div></div>
              : <div className='redText'>The Access Key you have entered is incorrect or does not exist on our system.<br /><br /></div>}
              <input className='AccessKeyInput'
              placeholder='Access key'
              onChange ={e => setAccessKey(e.target.value)}
              />
              <button className='submitButton' disabled={!AccessKey} onClick={() => Connect()} >Verify</button>
              </div>
             :
            <div>
              <h2>Your username must be unique & Your password must follow these requirments:</h2>
              <ul>
              <li>Password must be at least 8 characters.</li>
              <li>Password must contian both uper, lower case letters & numbers.</li>
              </ul>
            
              {CreateLovedOneError === true ?
              <p className="redText">Please ensure all felids have been filled out!</p>
              :<p></p>}

              {CreateLovedOneSuccess === true ?
              <p className="greenText">You have successfully created a loved one!</p>
              :<p></p>}

              <p>Username*</p>

              <input className='AccessKeyInput'
              placeholder='User name'
              onChange ={e => setCreateUsername(e.target.value)}
              />
               <p>Password*</p>
              <input className='AccessKeyInput'
              placeholder='Password'
              type={showLovedOnePassword ? "text" : "password"}
              onChange ={e => setCreatePassword(e.target.value)}
              />
               <button onClick={() => showLovedOnePass()} className='eyeButton'>
                    {!showLovedOnePassword
                      ? <FaEyeSlash name="eye-outline" size={25} color="#A7D3D5" />
                      : <FaEye name="eye-off-outline" size={25} color="#A7D3D5" />
                    }
              </button>
               <p>Firstname*</p>
              <input className='AccessKeyInput'
              placeholder='First name'
              onChange ={e => setFirstName(e.target.value)}
              />
               <p>Lastname*</p>
              <input className='AccessKeyInput'
              placeholder='Last name'
              onChange ={e => setlastname(e.target.value)}
              />
              <button className='submitButton'  onClick={() => CreateLovedOne()} >Create</button>
              </div>
            }
            </CustomPopup>
            
            </div>
            </div>
            : " "}
          </Col>  
          { ConTab
            ? 
           <div>
            <div className="ConNameDiv">
            {displays.map((display) => (
              Id === display.id ?
            <img key={display.id} className="ConNameImg" src={`https://intouch.global/uploads/profile/${display.display_picture} `} alt="Con_img" />
            :<p></p>
            ))}
            {displays.map((display) => (
              Id === display.id ?
            <p key={display.id} className="ConName">{display.displayname}</p>
            :<p></p>
            ))}
            </div>

            <div className="ConNameDiv1">
            {Admin  ? 
            <div>
            <p className="adminText">If you wish to assign one of the connections as an admin instead of yourself or remove them, you can do so by clicking on them.</p>
            <h1>Connections:</h1>

            <div className="famScroll">
            {famdisplays.map((famdisplay) => (
              <p onClick={(e) => {setVisibility(!visibility); Editconnection(); setFamId(famdisplay.id);}}>{famdisplay.displayname}</p>
            ))}
            </div>

            <button className='submitButton' onClick={(e) => {setVisibility(!visibility); invite();}}>Invite a connection</button>
            <br/>
            <button className='submitButton' onClick={(e) => {setVisibility(!visibility); resetshow();}}>Reset Password</button>


            <CustomPopup
              onClose={popupCloseHandler}
              show={visibility}
              >

              { Invite ?
            <div>
              <h1>The access code is:</h1>
              <h3>{invitedisplays}</h3>
              
              { /*invitedisplays.map((invitedisplay) => (
                  <p>{invitedisplay}</p>
              ))*/}
              </div>
            : <div>
            {Edit 
              ?
              <div>
                <h1>Edit Connection</h1>
                {famdisplays.map((famdisplay) => (
              FamId === famdisplay.id ?
            <img key={famdisplay.id} className="ConNameImgSmall" src={`https://intouch.global/uploads/profile/${famdisplay.display_picture} `} alt="Con_img" />
            :<p></p>
            ))}
            {famdisplays.map((famdisplay) => (
              FamId === famdisplay.id ?
            <h2 key={famdisplay.id} >{famdisplay.displayname}</h2>
            :<p></p>
            ))}
                
            <p>If you remove family member from loved ones connections they will no longer be able to see the loved ones social.</p>
            {RemoveConnectionError === true?
            <p className="ReomoveConBox">You cannnot remove this connection because you are Admin for this user. To remove this connction, please transfer Admin to another connection.</p>
            :<p></p>
            }
               {RemoveConnectionSuccess === true ?
                <p className="greenText">You have successfully removed the family member from the loveded one's acount!</p>
                :<p></p>
               }
                <button className='submitButton' onClick={() => RemoveConnection()}>Remove Connection</button>
                <br/>
                <br/>
                {famdisplays.map((famdisplay) => (
              FamId === famdisplay.id ?
                <p>Would you like to assign {famdisplay.displayname} as Admin?</p>
                :<p></p>
            ))}
              <p>Once Admin is transfered, you will no longer be the Admin for this account.</p>
              {/*Maybe make the current admin not viewable in the connections */}
              {AssignAdminError === true ?
              <p className="ReomoveConBox">You cannnot transfer admin for this connction, as you are already admin.</p>
              :<p></p>
              }
              {famdisplays.map((famdisplay) => (
                AssginAdminSuccess === true /*& FamId === famdisplay.id*/ ?
              <p className="greenText">You have successfully transfered admin to {famdisplay.displayname}</p>
              :<p></p> 
            ))}
         
                <button className='submitButton' onClick={() => AssignAdmin()}>Assign as Admin</button>
              </div>

              :<div>

            {VerifySuccess !== true?
            <div>
            <h1>Verify your password</h1>
            <p>Please verify your password to move to the next step.</p>
            <p>Password*</p>
            {VerifyError === true ?
            <div className='redText'>The Password you have entered is incorrect<br /><br /></div>
            :<div/>}

              <input className='AccessKeyInput'
              placeholder='Password'
              type={showVerifyPassword ? "text" : "password"}
              onChange ={e => setPassword(e.target.value)}
              />
              <button onClick={() => showVerifyPass()} className='eyeButton'>
                    {!VerifyPassword
                      ? <FaEyeSlash name="eye-outline" size={25} color="#A7D3D5" />
                      : <FaEye name="eye-off-outline" size={25} color="#A7D3D5" />
                    }
              </button>
             
              <button className='submitButton' disabled={!Password} onClick={() => Verify()}>Verify</button>
            </div>
            :
              <div>
              {displays.map((display) => (
                Id === display.id ?
                <h1>Change password for {display.displayname}</h1>
                :<h1></h1>
                ))}
                <p>The new password should be diffrent from their current password and follow these requirments:</p>
                <ul>
              <li>Password must be at least 8 characters.</li>
              <li>Password must contian both uper, lower case letters & numbers.</li>
              </ul>

              {noMatch === true ?
                <div className='redText'>The Passwords don't match<br /></div>
                :<div/>
              }

              {Match === true ?
                <div className='greenText'>Password changed successfully!<br /></div>
                :<div/>
              }
            
              <p>New Password</p>
              <div className='inputCont'>
              <input className='AccessKeyInput'
              placeholder='New Password'
              type={showNewPassword ? "text" : "password"}
              onChange ={e => setNewPassword(e.target.value)}
              />

              <button onClick={() => showNewPass()} className='eyeButton'>
                    {showNewPassword
                      ? <FaEyeSlash name="eye-outline" size={25} color="#A7D3D5" />
                      : <FaEye name="eye-off-outline" size={25} color="#A7D3D5" />
                    }
              </button>
              </div>

                <p>Re-enter Password</p>
                <div className='inputCont'>
              <input className='AccessKeyInput'
              placeholder='Re-enter Password'
              type={showConfPassword ? "text" : "password"}
              onChange ={e => setConfPassword(e.target.value)}
              />

              <button onClick={() => showConfPass()} className='eyeButton'>
                    {showConfPassword
                      ? <FaEyeSlash name="eye-outline" size={25} color="#A7D3D5" />
                      : <FaEye name="eye-off-outline" size={25} color="#A7D3D5" />
                    }
              </button>
              </div>

              <button className='submitButton' onClick={() => changePassword()}>Change Password</button>

              </div>
            }
            </div>
              }
</div>}
            </CustomPopup>
            </div>
            :
            <div>
            <p className="adminText">If you wish to disconnect from loved one, be aware it will remove you from their connections and you will no longer be able to view them as a connection.</p>
            <button className='submitButton' onClick={(e) => {setVisibility(!visibility); disconnectshow();}}>Disconnect</button>
            </div>
            }
            {disconnect 
            ?
           <div>
            <CustomPopup
              onClose={popupCloseHandler}
              show={visibility}
              >
            { !disconnectAcknowledge 
            ?
            <div>
              <h1>Are you sure you would like to Disconnect from Loved-one?</h1>
              <button className='submitButton' onClick={() => Disconnect()}>Disconnect</button>
              </div>
            : <h1 className="greenText">You have successfully disconnected from Loved-one!</h1>}
            </CustomPopup>
            </div>
            :<div></div>}
            </div>
            </div>
            :<Row className='blankDiv' key="b" data-grid={{ x: 1, y: 0, w: 3, h: 2, static: true  }}>
            
            </Row>}
                      
          </Row>
      </Grid>
          

  );
          
}


export default Connections;